<template>
  <el-dialog
    v-if="qrCodeDialog.show"
    :title="qrCodeDialog.title"
    :visible.sync="qrCodeDialog.show"
    center
    width="30%"
    top="30vh"
    @close="closeDialog"
  >
    <div class="main-wrap">
      <!-- <div class="title">{{qrCodeDialog.title}}</div> -->
      <div class="cont">
        <img :src="qrCodeDialog.imgUrl" />
      </div>
      <div class="tips">请使用微信扫描二维码进行支付</div>
      <div class="price">¥ {{ qrCodeDialog.price }}</div>
      <div class="btn-wrap">
        <!-- <el-button type="info">付款遇到问题</el-button> -->
        <el-button @click="handlePayed" type="primary">已完成付款</el-button>
      </div>
      <div class="remark">支付完成，请点击完成支付按钮</div>
    </div>
  </el-dialog>
</template>
<script>
import {confirmOrder, getIspaid} from "@/api/order";
export default {
  name: "ApplyRefund",
  props: {
    qrCodeDialog: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // codeImgUrl: 'https://www.wlmqcol.com/common/qrcode?text=weixin%3A//wxpay/bizpayurl%3Fpr%3DWUPdroszz'
    };
  },
  methods: {
    // 支付状态查询
    handlePayed() {
      getIspaid({ orderId: this.qrCodeDialog.orderId }).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          console.log("0");
          if (res.data.data.isPaid === false) {
            this.$message({
              type: "error",
              message: "订单未支付，请重新扫码进行支付操作！",
            });
          } else {
            /* this.$confirm(
                `${res.data.data.statusName}`,
                '温馨提示',
                {
                  confirmButtonText: '回首页',
                  showCancelButton: false,
                  // cancelButtonText: '取消',
                  type: 'warning',
                  showClose: false,
                  closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
                  closeOnPressEscape: false //是否可通过按下 ESC 键关闭 MessageBox
                }
              ).then(() => {
                this.$router.push({
                  path: '/'
                })
              }) */

            this.$message({
              type: "success",
              message: "支付成功,跳转课程页面！",
              duration: 500,
              noClose: () => {
                /* this.$router.push({
                  path: '/'
                }) */
              },
            });
            setTimeout(() => {
              confirmOrder({ orderId: this.qrCodeDialog.orderId });
            })
            setTimeout(() => {
              this.$router.push({
                path: `/classdetail?id=${this.qrCodeDialog.courseId}&type=${this.qrCodeDialog.courseType}`,
              });
            }, 500);
          }
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
      console.log("ing...");
    },
    //关闭回调
    closeDialog() {
      confirmOrder({ orderId: this.qrCodeDialog.orderId });
      this.$emit("dialogClose");
    },
  },
};
</script>
<style lang="scss" scoped>
.main-wrap {
  .title {
    margin-bottom: 1.25rem;
  }
  .cont {
    text-align: center;
  }
  .tips {
    color: #999;
    text-align: center;
  }
  .price {
    margin-top: 10px;
    font-size: 16px;
    color: #f00;
    font-weight: bold;
    text-align: center;
  }
  .remark {
    font-size: 14px;
    color: #f00;
    text-align: center;
    margin-top: 10px;
  }
  .btn-wrap {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
}
</style>