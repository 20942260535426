<template>
  <div class="payment">
    <header-t />
    <nav2 />
    <div class="cont">
      <el-card shadow="nerve" class="card">
        <div class="theSteps">
          <el-steps :active="active" finish-status="success">
            <el-step title="订单确认"></el-step>
            <el-step title="订单支付"></el-step>
            <el-step title="订单完成"></el-step>
          </el-steps>
        </div>
        <div class="item">
          <div class="title"><span class="tag"></span>订单信息：</div>
          <div class="order-info">
            <div class="pro-img">
              <img :src="coursePic" alt="" />
            </div>
            <div class="order-row">
              <p>
                订单号：<span>{{ info.orderId }}</span>
              </p>
              <p>
                订单名称：<span>{{ info.productName }}</span>
              </p>
              <p>
                订单价格：<span class="price">¥{{ info.payPrice }}</span>
              </p>
            </div>
          </div>
          <div class="tips">
            订单已提交成功，请在48小时内完成支付！逾期订单将被取消。
          </div>
        </div>
        <div class="item">
          <div class="PayMethod"><span class="tag"></span>支付方式：</div>
          <div class="pay-cont">
            <el-radio-group v-model="payRadio">
              <el-radio
                v-for="item in payData"
                :key="item.payMethod"
                :label="item.payMethod"
                disabled
                class="pay-radio"
              >
                <img
                  :src="item.payImg"
                  :class="item.payMethod == payRadio ? 'border' : ''"
                />
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="btn-wrap">
          <!-- <img src="https://www.wlmqcol.com/assets/img/order/wechat.png?version=8.8.0"   @click="handlePayMethod(index)" :class="{img}"> -->
          <el-button @click="handlePay" :disabled="isBuging" type="primary"
            >立即支付</el-button
          >
        </div>
      </el-card>
      <!-- 支付二维码弹窗 -->
      <QrCode
        v-if="qrCodeDialog.show"
        :qr-code-dialog="qrCodeDialog"
        @dialogClose="dialogClose"
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderT from "@/components/HeaderT";
import Nav2 from "@/components/Nav2";
import Footer from "@/components/Footer";
import { getPayMethod, getOrderDetail, getNative } from "@/api/order";
import QrCode from "./components/QrCode.vue"; // 引入组件
export default {
  name: "Payment",
  components: {
    HeaderT,
    Nav2,
    Footer,
    QrCode,
  },
  data() {
    return {
      active: 2, // 步骤图激活状态
      isBuging: false,
      index: 0,
      payData: [],
      payRadio: null,
      coursePic: "",
      info: {},
      qrCodeDialog: {},
      payImage: "", // 支付款二维码
      orderId: "", // 订单id
    };
  },
  watch: {
    /* payRadio: {
      handler(newVal,oldVal) {
        console.log(newVal, oldVal);
        if(newVal !== null) {
          this.isBuging = false
        }else{
          this.isBuging = true
        }
      }
    } */
  },
  mounted() {
    this.getOderDetail(this.$route.query.orderId);
    this.getPayMethod();
  },
  methods: {
    //获取订单信息
    getOderDetail(orderId) {
      getOrderDetail(orderId).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.info = res.data.data;
          this.payRadio = res.data.data.payMethod;
          this.orderId = res.data.data.orderId;
          this.courseType = res.data.data.courseType;
          this.coursePic = res.data.data.productImg
          if (res.data.data.status !== 1) {
            // 订单已支付，跳首页
            this.$confirm(`${res.data.data.statusName}`, "温馨提示", {
              confirmButtonText: "回首页",
              showCancelButton: false,
              // cancelButtonText: '取消',
              type: "warning",
              showClose: false,
              closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
              closeOnPressEscape: false, //是否可通过按下 ESC 键关闭 MessageBox
            }).then(() => {
              this.$router.push({
                path: "/",
              });
            });

            /* this.$message({
              type: 'info',
              message: res.data.data.statusName,
              duration:5000,
              noClose: ()=>{
                debugger
                this.$router.push({
                  path: '/'
                })
              }
            }) */
          }
        }else{
          // 订单已支付，跳首页
            this.$confirm(`${this.$route.query.orderId}${res.data.message}!`, "温馨提示", {
              confirmButtonText: "回首页",
              showCancelButton: false,
              // cancelButtonText: '取消',
              type: "error",
              showClose: false,
              closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
              closeOnPressEscape: false, //是否可通过按下 ESC 键关闭 MessageBox
            }).then(() => {
              this.$router.push({
                path: "/",
              });
            });

          /* this.$message({
            type: 'error',
            message: res.data.message
          }) */
        }
      });
    },
    // 获取支付方式
    getPayMethod() {
      getPayMethod().then((res) => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            this.payData = res.data.data.payMethods;
            // this.payRadio = this.payData[0].payMethod;
          }
        }
      });
    },
    //提交立即支付
    async handlePay() {
      //校验是否选择支付方式
      if (this.payRadio === null) {
        this.$message({
          type: "error",
          message: "请选择支付方式！",
        });
        return;
      } else {
        this.isBuging = true;
        const obj = {
          orderId: this.$route.query.orderId,
        };
        getNative(obj).then((res) => {
          if (res.data.code === 0) {
            console.log("success");
            this.payImage = `data:image/png;base64,${res.data.data.bgPic}`;
            // 显示二维码弹窗
            this.qrCodeDialog.show = true;
            this.qrCodeDialog = {
              show: true,
              title: "微信支付",
              imgUrl: this.payImage,
              price: res.data.data.payPrice,
              orderId: this.orderId,
              courseId: this.$route.query.courseId,
              courseType : this.courseType
            };
          } else {
            this.isBuging = false;
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
      }
    },
    // 弹窗关闭回调
    dialogClose() {
      this.isBuging = false;
    },
    //
    handleSubmit() {
      console.log("handleSubmit...");
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.payment {
  height: 100%;
  background-color: #f5f5f5;
  .cont {
    width: 1170px;
    margin: 20px auto;
    .card {
      .theSteps {
        text-align: left;
      }
    }
  }
  .item {
    margin-top: 20px;
    width: 100%;
    .title {
      font-size: 14px;
      color: #333;
      font-weight: bold;
      text-align: left;
      border-bottom:1px #ebebeb solid;
      padding-bottom: 8px;
      .tag{
        border-left:3px solid #409EFF;
        margin-right: 10px;
      }
    }
    .tableData {
      margin-top: 20px;
    }
    .tips {
      font-size: 14px;
      color: #666;
      font-weight: bold;
      text-align: left;
      margin: 1px 0 0;
      background-color: #fff4e4;
      color: #ffa51f;
      padding: 16px;
    }
    .order-info {
      // display: flex;
      width: 100%;
      height: auto;
      overflow: hidden;
      background-color: #f9f9f9;
      margin-top: 20px;
      padding: 10px;
      box-sizing: border-box;
      .pro-img {
        float: left;
        width: 220px;
        // height: 150px;
        img {
          width: 200px;
          height: 150px;
        }
      }
      .order-row {
        width: auto;
        float: left;
        p {
          font-size: 14px;
          line-height: 23px;
          text-align: left;
          color: #666;
          margin: 10px 0;
          span {
            font-weight: bold;
            color: #333;
          }
          .price {
            color: red;
          }
        }
      }
    }
    .img {
      width: 200px;
      height: 150px;
    }
    .PayMethod {
      font-size: 14px;
      font-weight: bold;
      text-align: left;
      border-bottom:1px #ebebeb solid;
      padding-bottom: 8px;
      margin-top: 30px;
      .tag{
        border-left:3px solid #409EFF;
        margin-right: 10px;
      }
    }
    .pay-cont {
      text-align: left;
      margin-top: 20px;
      .pay-radio {
        // display: flex;
      }
      img {
        border: none;
        padding: 3px;
        border: 2px #f5f5f5 solid;
        width: 150px;
        height: 50px;
        margin-top: 0;
      }
      .border {
        border: 1px #43bc60 solid;
      }
      /deep/ .el-radio__input {
        display: none;
      }
    }
    img {
      float: left;
      margin-top: 5px;
      border: 1px solid #eee;
      padding: 5px;
    }
    .el-button--medium {
      margin-top: 50px;
    }
  }
  .btn-wrap {
    margin-top: 20px;
    text-align: right;
  }
}
</style>